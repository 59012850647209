// Angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
// Rxjs
import { BehaviorSubject } from 'rxjs';
// Ngrx plugin
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
// Caloudi
import { LayoutService } from '@base/service/layout';
import { CollectionUtil, CommonUtil, MenuUtil } from '../util';
// Store
import { AppState } from '@Reducers';
import { getBreadcrumbs, getTabMenuFlatBreadcrumbs } from '@Selectors';
// Interface
import { LayoutType } from '@base/model';
import { Breadcrumb } from '../model/breadcrumb';

@Injectable({ providedIn: 'root' })
export class PageGuard implements CanActivate {
  private readonly breadcrumbs$: BehaviorSubject<Breadcrumb[]>;
  private readonly tabMenuBreadcrumbs$: BehaviorSubject<string[]>;

  constructor(
    private readonly logger: NGXLogger,
    private readonly router: Router,
    private readonly store: Store<AppState>,
    private readonly layoutService: LayoutService
  ) {
    this.breadcrumbs$ = CommonUtil.observable2BehaviorSub(this.store.select(getBreadcrumbs));
    this.tabMenuBreadcrumbs$ = CommonUtil.observable2BehaviorSub(this.store.select(getTabMenuFlatBreadcrumbs));
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.logger.trace('PageGuard stateUrl:', [
      route,
      state,
      this.breadcrumbs$,
      this.tabMenuBreadcrumbs$,
      MenuUtil.isRouterLink(this.breadcrumbs$.value, state.url),
    ]);

    /**
     * LayoutTypes: 0 (Legacy)
     */
    if (this.layoutService.config.layoutType === LayoutType.LEGACY) {
      if (
        CollectionUtil.isNotEmpty(this.breadcrumbs$.value) &&
        !MenuUtil.isRouterLink(this.breadcrumbs$.value, state.url)
      ) {
        this.logger.warn('accessdenied:', [
          route,
          state,
          this.breadcrumbs$.value,
          this.layoutService.config.layoutType,
        ]);
        this.router.navigate(['/accessdenied']);
      }
      this.logger.trace('PageGuard Check OK');
      return true;
    } else if (this.layoutService.config.layoutType === LayoutType.TABMENU) {
      /**
       * LayoutTypes: 1 (Tab Menu)
       */
      this.logger.trace('LayoutTypes1:', [route, state]);
      if (
        CollectionUtil.isNotEmpty(this.tabMenuBreadcrumbs$.value) &&
        !this.tabMenuBreadcrumbs$.value.some(tab => new RegExp(tab).test(state.url))
      ) {
        this.logger.warn('accessdenied:', [
          route,
          state,
          this.tabMenuBreadcrumbs$.value,
          this.layoutService.config.layoutType,
        ]);
        this.router.navigate(['/accessdenied']);
      }
      this.logger.trace('PageGuard Check OK');
      return true;
    } else {
      /**
       * Future LayoutTypes
       */
      this.router.navigate(['/accessdenied']);
      return true;
    }
  }
}
