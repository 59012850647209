// Angular
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
// Rxjs
import { NGXLogger } from 'ngx-logger';
import { iif, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
// Caloudi
import { AuthService } from '@core/service/api';
import { AuthenticationService } from '@core/service/config';
// Store
import { AutoLoginAction, TokenExpiredAction } from '@Actions';
import { Store } from '@ngrx/store';
import { AppState } from '@Reducers';
// Interface
import { AuthUser } from '@core/model';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private readonly logger: NGXLogger,
    private readonly store: Store<AppState>,
    private readonly router: Router,
    private readonly authenticationService: AuthenticationService,
    private readonly authService: AuthService
  ) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const paramKeys = route.queryParamMap.keys;
    const encUserToken = route.queryParamMap.get('encUserToken');
    this.logger.trace('authGuard:', [route, state, paramKeys, route.queryParamMap, encUserToken]);

    return iif(
      // Condition
      () => encUserToken !== null,

      // True Result
      this.authService.autoLogin(encUserToken).pipe(
        map(authUser => {
          this.logger.debug('auto login', [authUser, this.router]);
          this.store.dispatch(AutoLoginAction({ authUser: { ...authUser } }));
          // return true;
          return !!authUser;
        }),
        catchError((err: HttpErrorResponse) => {
          this.logger.debug('err', err);
          // if (!err.ok) history.back();
          if (!err.ok) this.router.navigate(['/login']);
          return of(!!err.ok);
        })
      ),

      // False Result
      of(false).pipe(
        map(() => {
          const currentRoute = state.url;
          this.logger.trace('currentRoute', currentRoute);
          const currentUser: AuthUser = this.authenticationService.currentUser.value;
          this.logger.trace('auth guard currentUser:', [currentUser]);
          if (currentUser?.accessToken) {
            let { expireDuration } = this.authenticationService.getTokenDuration();
            if (expireDuration < 0) {
              this.store.dispatch(TokenExpiredAction({ authUser: currentUser }));
              return false;
            }
            // Authorized so return true
            return true;
          } else {
            sessionStorage.setItem('loginReturnUrl', state.url);
          }
          this.logger.trace('AuthGuard to login page');
          // Not logged in so redirect to login page with the return url
          this.router.navigate(['/login']);
          return false;
        }),
        catchError((err, cau) => {
          if (err) {
            console.error(err);
            return of(false);
          } else return cau;
        })
      )
    );
  }
}
